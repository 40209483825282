import React from "react";
import styles from "./team.module.scss";
import { LinkedIn, X } from "@mui/icons-material";

const Team = () => {
  return (
    <div className={styles.cover} id="download">
      <p className={styles.capitals}>MEET THE TEAM</p>
      <p className={styles.title}>20 years of collective startup experience.</p>
      <div className={styles.row}>
        <div className={styles.container}>
          <img
            src="/oliver.jpg"
            alt="Portrait of person"
            className={styles.image}
          />

          <p className={styles.capitals}>CEO</p>
          <span className={styles.title}>Oliver Porter</span>
          <span className={styles.subtitle}>
            Design-focused software engineer with 6 years of experience across
            bitcoin, blockchain, healthcare, and insurance industries.
          </span>
          <a href="https://www.linkedin.com/in/ollieporter/">
            <LinkedIn />
          </a>
        </div>
        <div className={styles.container}>
          <img
            src="/denis.jpg"
            alt="Portrait of person"
            className={styles.image}
          />
          <p className={styles.capitals}>CTO</p>
          <span className={styles.title}>Denis Lessard</span>
          <span className={styles.subtitle}>
            Full-stack software developer 5+ years of experience in e-commerce,
            SaaS, and ridesharing industries. Previously software developer at
            MavTek.
          </span>
          <a href="https://www.linkedin.com/in/denislessard/">
            <LinkedIn />
          </a>
        </div>
        <div className={styles.container}>
          <img
            src="/greer.jpg"
            alt="Portrait of person"
            className={styles.image}
          />
          <p className={styles.capitals}>CMO</p>
          <span className={styles.title}>Greer Hall</span>
          <span className={styles.subtitle}>
            Creative director with 8 years of experience leading digital
            marketing across hospitality, wellness, healthcare, technology and
            consumer goods.
          </span>
          <a href="https://www.linkedin.com/in/greerhall/">
            <LinkedIn />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Team;
