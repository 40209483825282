import React from "react";
import styles from "./gameRules.module.scss";

const GameRules = () => {
  return (
    <div className={styles.cover}>
      <div className={styles.row}>
        <div className={styles.container}></div>
        <div className={styles.column}>
          <p className={styles.capitals}>GAME RULES</p>
          <span className={styles.title}>1 City. 6 Beasts. 1 Winner.</span>
        </div>
        <div className={styles.container}></div>
        <div className={styles.container}></div>
      </div>
      <div className={styles.row}>
        <div className={styles.containerTwo}></div>
        <div className={styles.outerIconContainer}>
          <div className={styles.iconContainer}>
            <img
              src="/hive.png"
              alt="Hive of islands icon"
              className={styles.iconTwo}
            />
          </div>
          <p className={styles.subtitle}>
            <strong>Complete Islands</strong>
          </p>
          <span className={styles.subtitle}>
            Boost your knowledge by completing courses on bitcoin basics.
          </span>
        </div>
        <div className={styles.outerIconContainer}>
          <div className={styles.iconContainer}>
            <img
              src="/hotspot.png"
              alt="Hotspot location icon"
              className={styles.iconTwo}
            />
          </div>
          <p className={styles.subtitle}>
            <strong>Find Hotspots</strong>
          </p>
          <span className={styles.subtitle}>
            Jippi selects random hotspots in your city to disover bitcoin beasts
            each month.
          </span>
        </div>
        <div className={styles.outerIconContainer}>
          <div className={styles.iconContainer}>
            <img
              src="/catch.png"
              alt="Catch beast icon"
              className={styles.iconTwo}
            />
          </div>
          <p className={styles.subtitle}>
            <strong>Catch Beasts</strong>
          </p>
          <span className={styles.subtitle}>
            Catch bitcoin beasts by answering quiz questions about bitcoin
            topics.
          </span>
        </div>
        <div className={styles.outerIconContainer}>
          <div className={styles.iconContainer}>
            <img
              src="/ticket.png"
              alt="Ticket icon"
              className={styles.iconTwo}
            />
          </div>
          <p className={styles.subtitle}>
            <strong>Collect Tickets</strong>
          </p>
          <span className={styles.subtitle}>
            Redeem beasts for tickets and spin the bonus wheel for bitcoin
            prizes.
          </span>
        </div>
        <div className={styles.containerTwo}></div>
      </div>
    </div>
  );
};

export default GameRules;
