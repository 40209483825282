import React from "react";
import styles from "./eula.module.scss";
import { Link } from "react-router-dom";

const EULA = () => {
  return (
    <div className={styles.cover}>
      <p className={styles.title}>Jippi End User License Agreement</p>
      <section>
        <p>INTRODUCTION</p>
        <p>Last Updated: March 6th, 2025</p>
        <p>
          IMPORTANT: THIS SOFTWARE END USER LICENSE AGREEMENT (“EULA”) IS A
          LEGAL AGREEMENT BETWEEN YOU (THE “LICENSEE” OR “YOU”) AND THE LICENSOR
          - JIPPI, INC. (“JIPP” OR “WE“). USE OF THE SOFTWARE, CONTENT, AND
          SERVICES PROVIDED UNDER THE TERMS OF THIS EULA (THE “SOFTWARE”)
          CONSTITUTES YOUR ACCEPTANCE OF THESE TERMS. READ IT CAREFULLY BEFORE
          COMPLETING THE INSTALLATION PROCESS AND USING THE SOFTWARE. IF YOU DO
          NOT AGREE TO THE TERMS OF THIS EULA, DO NOT INSTALL AND/OR USE THIS
          SOFTWARE. BY DOWNLOADING, INSTALLING, COPYING, OR OTHERWISE USING THE
          SOFTWARE PRODUCT, YOU AGREE TO BE BOUND BY THE TERMS OF THIS EULA.
        </p>
        <p>
          The types of licenses provided with the Software are listed below.
          Only one license type can be applied to each Licensee. If you need to
          determine your license type, please visit our Support Center.
        </p>
      </section>
      <section>
        <p>1. LICENSE GRANT</p>
        <p>
          PERSONAL LICENSE. This type of license permits software operation on
          one individual user account or one computer, workstation, or another
          digital electronic device that conforms to the system requirements of
          the Software as specified in the documentation. Therefore, the
          Software is licensed on a per computer basis, not per user, per site,
          or per company. In accordance with the terms of this EULA, Jippi
          grants you a limited, worldwide, non-exclusive, non-transferable right
          to use the Software. As part of the personal license, “use” means
          storing, loading, installing, executing, or displaying the Software
          exclusively for personal purposes, without rights of commercial
          replication and/or distribution either in full or as part of the
          derived software to any third parties. You may not modify the Software
          or disable any licensing or control features of the Software except as
          an intended part of the Software programming features. Unless
          terminated as described herein, the given EULA remains effective in
          perpetuity and in consideration of appropriate restrictions in
          accordance with applicable laws and regulations.
        </p>
      </section>
      <section>
        <p>2. FREE (TRIAL) VERSION</p>
        <p>
          You may be granted the use of an evaluation copy of the Software free
          of charge for a finite period of time (“Free License” or “Free Copy”).
          Certain features and/or functionality in the Software may be locked or
          unavailable in the Free Copy
        </p>
        <p>
          To use the Free Copy, you may also be required to create an account.
        </p>
      </section>
      <section>
        <p>3. SUBSCRIPTION TERMS</p>
        <p>
          When you purchase a Software license on a subscription basis, you
          acknowledge and agree that you are authorizing recurring payments to
          be made to Jippi, and those payments shall be made by the method you
          have chosen at the recurring intervals chosen by you until the
          subscription is terminated by you or by Jippi in accordance with this
          EULA. You will receive notice prior to each payment date, and, unless
          you terminate your subscription, it will be automatically renewed.
          Jippi will automatically charge you the then-current rate for your
          subscription plan, plus applicable taxes, for every billing period of
          your purchase order.
        </p>
        <p>
          If your account has insufficient funds on the date the payment is due,
          your subscription will be canceled on the next day after the last day
          of the previous paid period.
        </p>
        <p>
          Subscription fees are generally billed or charged in advance of the
          applicable subscription period. If a debit attempt is unsuccessful, we
          will make another attempt on the subscription expiration date and may
          make such attempts again within seven (7) calendar days of the
          expiration date. If any of these attempts are successful, your
          subscription will be renewed for a new term. The new subscription
          period will be calculated from the next day after the end date of the
          previous paid period.
        </p>
        <p>
          Jippi may change your plan's rate or payment terms of your
          subscription each renewal term, and we will notify you of any change
          in advance and provide an option to cancel.
        </p>
        <p>
          You should routinely check the information about the pre-paid license
          period or pre-paid access to the online services period, the prices,
          and other terms and conditions, as well as the current EULA and other
          documents related to Jippi Software and services amendments.
        </p>
      </section>
      <section>
        <p>4. REFUND</p>
        <p>Subscription fees are non-refundable.</p>
      </section>
      <section>
        <p>5. USER ACCOUNT</p>
        <p>
          To use the Software, you will be required to create a user account.
          This user account stores information about the products you have
          purchased, the subscription terms, your software activation codes, and
          other information about the products.
        </p>
        <p>
          In registering your user account, you are responsible for the
          completeness and accuracy of the information provided therein. You are
          also solely responsible for all activities that occur under your
          account, including liability for losses incurred by Jippi or third
          parties as a result of using your account, regardless of whether the
          account was used with your knowledge.
        </p>
        <p>
          In the event of unauthorized use of your account, you agree to notify
          Jippi immediately.
        </p>
        <p>You may not use someone else's user account.</p>
        <p>
          You have the right to delete your user account at any time, but in
          doing so you will lose access to the Software, which requires a user
          account to be used. You are given 30 calendar days during which time
          you may change your decision. After 30 calendar days, all information
          in your user account will be permanently deleted.
        </p>
      </section>
      <section>
        <p>6. OWNERSHIP</p>
        <p>
          The Software is owned and copyrighted by Jippi. Your license confers
          no title or ownership in the Software and should not be construed as a
          sale of any right in the Software. Jippi reserves all rights not
          expressly granted in this EULA.
        </p>
      </section>
      <section>
        <p>7. COPYRIGHT</p>
        <p>
          The Software and all rights, without limitation, including proprietary
          rights therein, are owned by Jippi or its suppliers and are protected
          by copyright laws and international copyright treaties as well as
          other intellectual property laws and treaties. The Software is
          licensed, not sold. You acknowledge that no title to the intellectual
          property in the Software is transferred to you. You further
          acknowledge that title and full ownership rights to the Software will
          remain the exclusive property of Jippi and you will not acquire any
          rights to the Software except as expressly set forth in this license.
          You agree that any copies of the Software will contain the same
          proprietary notices that appear on and in the Software.
        </p>
      </section>
      <section>
        <p>8. RESTRICTIONS</p>
        <p>
          You may not, directly or indirectly: (a) attempt to reverse compile,
          modify, translate, or disassemble the Software in whole or in part;
          (b) interfere or attempt to interfere with or disrupt the integrity,
          security, functionality, or performance of the Software or its
          components; (c) hack or otherwise attempt to gain unauthorized access
          to the Software; (d) sublicense, resell, rent, lease, transfer,
          assign, time share or otherwise commercially exploit or make the
          Software or any portion thereof available to any third party; (e)
          transmit through the Software any material that may infringe,
          misappropriate, or violate the rights of third parties; or (f) use the
          Software for any purpose or in any manner that is unlawful or is
          prohibited by this agreement.
        </p>
      </section>
      <section>
        <p>9. NO OTHER WARRANTIES</p>
        <p>
          THE SOFTWARE IS PROVIDED “AS IS” WITHOUT WARRANTY OF ANY KIND. JIPPI
          DISCLAIMS ALL OTHER WARRANTIES WITH RESPECT TO THE SOFTWARE, EITHER
          EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES
          OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
          NONINFRINGEMENT OF THIRD-PARTY RIGHTS. SOME JURISDICTIONS DO NOT ALLOW
          THE EXCLUSION OF IMPLIED WARRANTIES OR LIMITATIONS ON HOW LONG AN
          IMPLIED WARRANTY MAY LAST OR THE EXCLUSION OR LIMITATION OF INCIDENTAL
          OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATIONS OR EXCLUSIONS MAY
          NOT APPLY TO YOU. THIS WARRANTY GIVES YOU SPECIFIC LEGAL RIGHTS AND
          YOU MAY ALSO HAVE OTHER RIGHTS THAT VARY FROM JURISDICTION TO
          JURISDICTION.
        </p>
        <p>
          JIPPI OFFERS NO WARRANTY OF ANY THIRD-PARTY CONTENT, IF THE SOFTWARE
          CONTAINS SUCH CONTENT. THERE IS NO WARRANTY OF ANY KIND, EXPRESS OR
          IMPLIED, REGARDING THIRD-PARTY CONTENT. The Software automatically
          references, displays, links to, and provides web services related to
          sites and information located worldwide on the Internet. Because Jippi
          has no control over such sites and information, Jippi makes no
          guarantees as to such sites and information, including, but not
          limited to: (a) the accuracy, availability, sequence, completeness,
          currency, content, validity, or quality of any such sites and
          information or (b) whether they may contain unintended or
          objectionable content. By using the Software, you acknowledge that
          Jippi makes no representations or warranties with regard to any sites
          or information displayed by or accessed through the Software.
        </p>
        <p>
          The Software may contain advertising information from Jippi or its
          partners.
        </p>
      </section>
      <section>
        <p>10. THIRD-PARTY ACKNOWLEDGMENTS</p>
        <p>
          Portions of the Software may utilize or include third-party software
          and other copyrighted material. The use of such material is governed
          by their respective terms.
        </p>
      </section>
      <section>
        <p>11. YOUR INFORMATION AND JIPPI'S PRIVACY POLICY</p>
        <p>
          Jippi may collect certain data and information from you in connection
          with your use of the Software and otherwise in connection with this
          EULA. All such data and information will be collected and used by
          Jippi in accordance with the{" "}
          <Link to={`/privacy-policy`}>Jippi Privacy Policy</Link>, which you
          acknowledge.
        </p>
      </section>
      <section>
        <p>12. JIPPI SUPPORT CENTER</p>
        <p>
          We are available to help you in any way we can to make your Software
          experience the best. Should you need assistance or if you are unable
          to find an answer to your question, please feel free to contact our
          Support Team at hello@jippi.app
        </p>
        <p>
          To respond to your queries, Jippi may from time to time ask you to
          provide information on your user data and experience, which will be
          used to assess Software usability and thereby improve and enhance our
          products and services. The primary purpose of collecting personal and
          other user information in these cases is to provide you with secure,
          smooth, efficient, and customized maintenance services. For example,
          Jippi may collect and use personal data related to you, as permitted
          or necessary to: provide technical support, customer support, and
          troubleshoot problems; inform you about service updates and issues;
          verify your identity; protect your and Jippi’s interests. Such data
          may include the following information: your full name, email address,
          and phone number. Additionally, our Support Team may request the
          special log files that include program error messages, the version of
          the Jippi program in question and its activation status, and the
          technical specifications of your device. This information is required
          to provide the necessary technical support and continue our research
          and development process.
        </p>
        <p>
          Jippi uses this information in strict compliance with the relevant
          Privacy Policy.
        </p>
      </section>
      <section>
        <p>13. CONTENT</p>
        <p>
          You agree and acknowledge that any information, data, text, photos,
          videos, or other materials (hereinafter referred to as the “Content”),
          whether publicly or privately provided to Jippi in utilization of
          Jippi software, services, or support (such as for the purposes of
          Jippi providing support services to you), shall be the sole
          responsibility of the person from whom such Content has been obtained.
          This means that you shall be solely responsible for all Content
          uploaded, posted, provided, or otherwise transmitted by you via the
          Jippi websites or products and file-sharing or file-hosting services
          and for the validity, legitimacy, completeness, applicability, and
          copyright compliance of that Content. Jippi shall not control any user
          Content. By posting, uploading, providing, or otherwise transmitting
          your Content to or through Jippi websites or products, you grant Jippi
          a worldwide, non-exclusive, perpetual, irrevocable, royalty-free,
          fully paid, sublicensable, and transferable license to use, host,
          store, archive, copy, modify, cache, encode, reproduce, distribute,
          transmit, synchronize, display, create derivative works from, and
          publicly perform such Content in connection with the performance of
          this EULA.
        </p>
      </section>
      <section>
        <p>14. UPGRADE AND UPDATE</p>
        <p>
          “Software Upgrade” (also known as a “Major Upgrade”) is a newer or
          improved version of the Software that offers a significant change or
          major improvement over your current version. Some of these versions
          may be provided as a component of your license subscription while
          others may be provided for an additional charge.
        </p>
        <p>
          “Software Update” (also known as a “Minor Update”) is a download of
          the Software that provides fixes for discovered bugs and malfunctions
          and/or provides minor software improvements. These versions are
          provided by Jippi within the period a particular version of the
          Software is being developed.
        </p>
        <p>
          Jippi does not guarantee the provision of updates for versions of the
          Software developed earlier and does not guarantee the operability and
          compatibility of the earlier purchased Software on devices, operating
          systems, and browsers that were released after the purchase of the
          Software. However, Jippi will continue to provide technical support
          (consultations and other help) in relation to the earlier purchased
          programs in accordance with the original system requirements, up to a
          point where selected versions reach end-of-life as determined and
          published by Jippi.
        </p>
        <p>
          Before using any Upgrade or Update to earlier purchased Software, you
          shall ensure that the upgraded or updated Software can be used on a
          particular device in accordance with the system requirements of the
          upgraded or updated program.
        </p>
        <p>
          Any Upgrades or Updates are provided by Jippi at its sole discretion
          without liabilities of any kind.
        </p>
      </section>
      <section>
        <p>15. SEVERABILITY</p>
        <p>
          In the event of invalidity of any provision of this license, the
          parties agree that such invalidity shall not affect the validity of
          the remaining portions of this license.
        </p>
      </section>
      <section>
        <p>16. NO LIABILITY FOR CONSEQUENTIAL DAMAGES</p>
        <p>
          IN NO EVENT SHALL JIPPI, ITS AFFILIATES, OR ITS SUPPLIERS BE LIABLE TO
          YOU FOR ANY CONSEQUENTIAL, SPECIAL, INCIDENTAL, OR INDIRECT DAMAGES OF
          ANY KIND ARISING OUT OF THE DELIVERY, PERFORMANCE, OR USE OF THE
          SOFTWARE, EVEN IF JIPPI HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
          DAMAGES. IN NO EVENT WILL JIPPI LIABILITY FOR ANY CLAIM, WHETHER IN
          CONTRACT, TORT, OR ANY OTHER THEORY OF LIABILITY, EXCEED THE LICENSE
          FEE PAID BY YOU, IF ANY.
        </p>
      </section>
      <section>
        <p>17. INDEMNIFICATION</p>
        <p>
          You will indemnify, defend, and hold Jippi, its affiliates, directors,
          officers, and employees harmless, at your expense, against any
          third-party claim, suit, action, or proceeding (each, an “Action”)
          brought against Jippi (and Jippi's directors, officers, employees, and
          affiliates) by a third party to the extent that such Action is based
          upon or arises out of (a) the use of the Content or data you provided
          to Jippi; (b) your noncompliance with or breach of this agreement; (c)
          your use of the Software; or (d) your violation of applicable law or
          any third-party right, including, without limitation, any privacy,
          intellectual property, or another proprietary right. This
          indemnification obligation will survive termination of this agreement.
          You may in no event enter into any settlement or like agreement with a
          third party that affects Jippi’s rights or binds Jippi in any way,
          without the prior written consent of Jippi. Jippi reserves the right,
          at its own expense, to assume the exclusive defense and control of any
          matter otherwise subject to indemnification by you, in which event you
          will cooperate with Jippi in asserting any available defenses.
        </p>
      </section>
      <section>
        <p>18. TERMINATION</p>
        <p>
          18.1. Without prejudice to any of its rights, Jippi may at any time
          terminate this EULA of its own accord, including, but not limited to,
          if it is obligated to do so in accordance with applicable law, the
          requirement of a government body or another authorized person or if
          Jippi decides to terminate the provision of the Software in your area.
        </p>
        <p>
          18.2. If Jippi terminates this EULA of its own accord, it will use
          reasonable efforts to notify you through your personal account or via
          the email address you provided no later than 30 calendar days prior to
          the termination date.
        </p>
        <p>
          18.3. You may stop using the Software at any time for any reason upon
          written notice to Jippi. Except as otherwise set forth in the Refund
          Policy, termination does not entitle you to a refund of any prepaid or
          unused fees and you agree to promptly pay all unpaid fees due through
          the end of the applicable license period.
        </p>
        <p>
          18.4. Upon any termination of this EULA for any reason, all provisions
          regarding indemnification, liability, and limits thereon, and any
          provisions which expressly or by their nature are required to survive
          such termination in order to achieve their purpose, shall so survive
          until it shall no longer be necessary for them to survive in order to
          achieve their purpose.
        </p>
      </section>
      <section>
        <p>19. GENERAL PROVISION</p>
        <p>
          19.1. This is the entire agreement between you and Jippi, which
          supersedes any prior agreement or understanding, whether written or
          oral, relating to the subject matter of this EULA. If any part of this
          EULA is found to be void and unenforceable, it will not affect the
          validity of the balance of the agreement, which shall remain valid and
          enforceable according to its terms.
        </p>
        <p>
          19.2. Jippi shall not be liable for failure or delay of performance of
          its obligations resulting from any condition beyond Jippi’s reasonable
          control, including but not limited to, a strike, blockade,
          governmental action, war, act of terrorism, riot, natural disaster, or
          failure or diminishment of power or telecommunications or data
          networks or services.
        </p>
        <p>
          19.3. Jippi may provide translations of the EULA or other Jippi terms
          or policies. Translations are provided for informational purposes and
          if there is an inconsistency or conflict between a translation and the
          English version, the English version will control.
        </p>
        <p>
          19.4. Jippi reserves the right to make amendments to this EULA. These
          amendments will take effect immediately after they are posted on the
          Jippi website. Jippi also has the right to upgrade, update, modify,
          and/or discontinue the Software (including any part or function
          thereof) at any time at its sole discretion. If we discontinue the
          Software in its entirety, we will use reasonable efforts to notify you
          in accordance with clause 21.2, and, according to your written
          request, we may check your eligibility to receive a refund, per our
          Refund Policy, and may provide you with a pro rata refund for any
          unused fees for the Software that you prepaid.
        </p>
        <p>
          19.5. This EULA and any dispute or claim arising out of or in
          connection with it or its subject matter or formation shall be
          governed by and construed exclusively in accordance with the laws of
          the State of Texas, USA.
        </p>
      </section>
    </div>
  );
};

export default EULA;
