import React from "react";
import styles from "./apiLicenseAgreement.module.scss";

const ApiLicenseAgreement = () => {
  return (
    <div className={styles.cover}>
      <p className={styles.title}>Jippi API License Agreement</p>
      <section>
        <p>INTRODUCTION</p>
        <p>Last Updated: March 6th, 2025</p>
        <p>
          This API License Agreement (hereinafter the “Agreement”) is a legally
          binding agreement by and between Jippi, Inc. (hereinafter Jippi, we,
          or us) and any developer that accesses the Jippi Services as defined
          below (hereinafter the “Company”).
        </p>
      </section>
      <section>
        <p>
          A. Jippi is a gamified social education platform whose purpose is to
          provide educational content and rewards to Bitcoin beginners, mainly
          by the means of providing content in the form of graphical assets,
          text, videos, animations, and alike (hereinafter “Jippi Content”).
          Said Services and Content may be delivered to the Company through an
          application program interface, software code, and accompanying
          documentation (hereinafter “Jippi API”).
        </p>
        <p>
          B. The Company wants to use the Jippi Content, served mainly via the
          Jippi API, to display it in its own media.
        </p>
        <p>
          C. Jippi is willing to grant such a license to the Company, subject to
          the terms and conditions of this Agreement.
        </p>
      </section>
      <section>
        <p>1. License.</p>
        <p>
          Subject to the Company's compliance with the terms and conditions of
          this Agreement, Jippi grants the Company a non-exclusive,
          non-transferable license to: (a) use the Jippi API to retrieve Jippi
          Content; (b) further display the Jippi Content to Company's final
          users; and (c) use and display the Jippi Logo with the purpose of
          informing final users about the providence of the content.
        </p>
      </section>
      <section>
        <p>2. Terms and Conditions.</p>
        <p>
          The license(s) granted to the Company are subject to the individual
          Terms and Conditions between Jippi and the Company as well as the
          following:
        </p>
        <p>
          2.1. Approval. Prior to the Company's use or incorporation of any
          Jippi Content with the Company Service, the Company will provide Jippi
          with examples of the proposed use of the Jippi Content in the Company
          Service for Jippi's prior written approval, which Jippi may grant or
          withhold in its sole discretion.
        </p>
        <p>
          2.2. Content Integrity. The Company agrees not to alter, modify, or
          create derivative works of the Jippi Content without Jippi's prior
          written approval. This restriction does not apply to minor formatting
          changes necessary to display the content appropriately on different
          devices.
        </p>
        <p>
          2.3. Restrictions. Company shall not use the Jippi API or Content for
          any purposes beyond the scope of the access granted in this Agreement.
          The Company shall not misuse the Jippi API in any way that could
          damage, disable, overburden, or impair it or interfere with any other
          party's use and enjoyment of the Jippi API and Content. Additionally,
          the Company may not use the Jippi Content in any manner that suggests
          an endorsement or association between Jippi and the Company without
          Jippi's prior written consent.
        </p>
        <p>
          2.4. Compliance with Laws. The Company will use the Jippi API and
          Content in compliance with all applicable local, state, national, and
          international laws, rules, and regulations.
        </p>
        <p>
          2.5. No Sublicensing. Company may not sublicense or transfer any of
          its rights under this Agreement without the prior written consent of
          Jippi.
        </p>
      </section>
      <p>3. Intellectual Property Rights.</p>
      <p>
        Jippi retains all rights, titles, and interests in and to the Jippi API
        and Content, including, without limitation, all related intellectual
        property rights. The rights granted to the Company in this Agreement are
        licensed (not sold) and are expressly conditioned on the Company's full
        compliance with the terms of this Agreement.
      </p>
      <section>
        <p>4. Termination.</p>
        <p>
          Jippi may terminate this Agreement immediately upon notice to the
          Company if the Company breaches any of the terms of this Agreement.
          Upon termination, the Company must immediately cease all use of the
          Jippi API and Content, and delete all copies of the Jippi Content.
        </p>
      </section>
      <p>5. Indemnification.</p>
      <p>
        The Company agrees to indemnify, defend, and hold harmless Jippi and its
        directors, officers, employees, and agents from and against any claims,
        damages, losses, liabilities, actions, judgments, costs, and expenses
        (including reasonable attorneys' fees) arising out of or relating to the
        Company's use of the Jippi API and Content or breach of this Agreement
      </p>
      <section>
        <p>6. Disclaimer of Warranties.</p>
        <p>
          Jippi provides the API and Content "as is" and expressly disclaims any
          warranties of merchantability, fitness for a particular purpose, or
          non-infringement. Jippi does not warrant that the API or Content will
          meet the Company's requirements or that the operation of the API will
          be uninterrupted or error-free.
        </p>
      </section>
      <p>7. Limitation of Liability.</p>
      <p>
        To the extent not prohibited by law, in no event will Jippi be liable
        for any direct, indirect, special, consequential, incidental, or
        punitive damages, including but not limited to, damages for loss of
        profits, goodwill, use, data, or other intangible losses, resulting from
        the use or inability to use the Jippi API or Content.
      </p>
      <section>
        <p>8. General Provisions.</p>
        <p>
          This Agreement constitutes the entire agreement between the parties
          and supersedes all prior agreements or communications. The Agreement
          may only be modified by a written amendment signed by an authorized
          executive of Jippi. If any provision of this Agreement is held to be
          unenforceable, such provision will be modified to reflect the parties'
          intention or eliminated to the minimum extent necessary so that the
          remaining provisions of the Agreement will continue in full force and
          effect.
        </p>
        <p>
          This Agreement is governed by the laws of the State of Texas, without
          regard to its conflict of laws principles. Any legal suit, action, or
          proceeding arising out of or related to this Agreement or the licenses
          granted hereunder shall be instituted exclusively in the federal
          courts of the United States or the courts of the State of Texas.
        </p>
        <p>
          By accessing and using the Jippi API, the Company acknowledges that it
          has read, understands, and agrees to be bound by the terms of this
          Agreement.
        </p>
      </section>
      <section>
        <p>9. Amendments.</p>
        <p>
          Jippi reserves the right to clarify or amend this Agreement by
          publicly publishing a new version of it.
        </p>
      </section>
      <section>
        <p>10. General.</p>
        <p>
          This Agreement (or any of the rights or obligations granted hereunder)
          may not be assigned by the Company except with the express written
          consent of Jippi, and any attempted assignment in violation of this
          paragraph is void.
        </p>
        <p>
          Jippi may freely assign this Agreement, in whole or in part. Subject
          to the foregoing, this Agreement is binding upon and will ensure the
          benefit of each of the parties and their respective successors and
          permitted assigns.
        </p>
        <p>
          This Agreement does not create or imply any partnership, agency, or
          joint venture relationship. Neither party will have the power to bind
          the other or incur obligations on the other's behalf without such
          other party's prior written consent.
        </p>
        <p>
          This Agreement will be governed by and construed in accordance with
          the laws of the State of Texas, without regard to or application of
          conflicts of law rules or principles. All claims arising out of or
          relating to this Agreement will be brought exclusively in the federal
          or state courts of Texas and the Company consents to the jurisdiction
          of those courts.
        </p>
        <p>
          All notices required or permitted under this Agreement will be in
          writing and will reference this Agreement.
        </p>
        <p>
          This Agreement is the complete and exclusive agreement between the
          parties with respect to its subject matter and supersedes all prior or
          contemporaneous agreements, communications, and understandings, both
          written and oral, with respect to its subject matter.
        </p>
        <p>
          If any part of this Agreement is determined to be invalid or
          unenforceable by an appropriate court, that provision will be enforced
          to the maximum extent permissible, and the remaining provisions of
          this Agreement will remain in full force and effect.
        </p>
        <p>
          {" "}
          Either party's failure to enforce any provision of this Agreement will
          not constitute a waiver of future enforcement of that or any other
          provision. No waiver of any provision of this Agreement will be
          effective unless it is in writing and signed by the party granting the
          waiver.
        </p>
        <p>
          Any use of Jippi Service by the Company or any of its end users will
          be subject to the Jippi Terms of Service (available at
          https://jippi.app).
        </p>
        <p>
          The parties have agreed to contract electronically, and accordingly,
          electronic signatures have the same weight and effect as originals.
        </p>
      </section>
      <section>
        <p>
          [The Agreement can be executed in counterparts, each of which shall be
          deemed original, and which together shall constitute one and the same
          agreement.]
        </p>
      </section>
    </div>
  );
};

export default ApiLicenseAgreement;
