import { hot } from "react-hot-loader/root";
import React from "react";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import "./App.scss";
import TermsOfService from "./components/TermsOfService";
import PrivacyPolicy from "./components/PrivacyPolicy";
import BeastCatch from "./components/BeastCatch";
import TribeClash from "./components/TribeClash";
import ErrorPage from "./components/Error";
import EULA from "./components/EndUserLicenseAgreement";
import SXSW from "./components/SXSW";
import SignUp from "./components/SignUp";
import PitchDeck from "./components/PitchDeck";
import ApiLicenseAgreement from "./components/ApiLicenseAgreement";
import BeastPrizeDraw from "./components/TribeClash/BeastPrizeDraw";
import Team from "./components/Team";

const App = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <BeastCatch />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/tribe-clash",
      element: <TribeClash />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/SXSW",
      element: <SXSW />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/bitcoin-beast-prize-draw",
      element: <BeastPrizeDraw />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/terms-of-service",
      element: <TermsOfService />
    },
    {
      path: "/privacy-policy",
      element: <PrivacyPolicy />
    },
    {
      path: "/end-user-license-agreement",
      element: <EULA />
    },
    {
      path: "/pitch-deck",
      element: <PitchDeck />,
    },
    {
      path: "/sign-up",
      element: <SignUp />,
    },
    {
      path: "/team",
      element: <Team />,
    },
    {
      path: "/api-license-agreement",
      element: <ApiLicenseAgreement />,
    }
  ]);

  return <React.StrictMode><RouterProvider router={router} /></React.StrictMode> 
};

export default hot(App);
