import React from "react";
import styles from "./privacyPolicy.module.scss";

const PrivacyPolicy = () => {
  return (
    <div className={styles.cover}>
      <p className={styles.title}>Jippi Privacy Policy</p>
      <section>
        <p>INTRODUCTION</p>
        <p>Last Updated: March 6th, 2025</p>
        <p>
          This Privacy Policy applies to personal information collected,
          processed, or stored by Jippi online stores, via the Sites (as
          hereinafter defined) and via Jippi's web and mobile applications and
          describes the extent of collection and use of personal information by
          Jippi, Inc. (hereinafter - Jippi, we, or us). Except as otherwise
          stated herein, Jippi is a data controller of personal information
          processed in accordance with this Privacy Policy. Jippi has the
          greatest respect for your privacy and gives special attention to the
          confidentiality of information submitted to us when you visit our
          websites (current and future websites, owned or operated by Jippi,
          and/or for which Jippi currently or in the future provides services
          and/or products, hereinafter - the Site or Sites) or otherwise
          communicate or interact with us.
        </p>
      </section>
      <section>
        <p>1. General provisions</p>
        <p>
          1.1. This document, the Jippi Privacy Policy (hereinafter - the
          Policy), contains our policies and provisions concerning the
          information collected about you by Jippi.
        </p>
        <p>
          1.2. By visiting our Site, using our services, and downloading,
          installing, and using the Jippi software (hereinafter - the
          Applications), you accept all terms and conditions of this Policy.
        </p>
        <p>
          1.3. This Policy is a part of a body of documents that sets terms and
          conditions of use of the Site and of the Applications. Other documents
          defining the relationship between you and Jippi are the Terms of Use
          and the End User License Agreement (hereinafter - EULA), along with
          any additional terms that could be applicable to your use of our
          products and/or services.
        </p>
        <p>
          1.4. Unless defined otherwise by this Policy, all terms and
          definitions used herein shall have the meaning defined in the Terms of
          Use and EULA.
        </p>
        <p>
          1.5. This Policy, including its construction, method of adoption,
          performance, amendments, and termination, is subject to the
          legislation of the State of Texas, USA.
        </p>
      </section>
      <section>
        <p>2. Information we collect</p>
        <p>
          2.1. We may collect information, including personal information, about
          you when you provide it to us, when you use our Sites, products, and
          services, and when other sources provide it to us, as further
          described below. Personal information means information that can be
          associated with a specific individual.
        </p>
        <p>2.2. We process the following categories of personal information:</p>
        <ul>
          <li>
            <p>Information provided directly by you</p>
          </li>
          <li>
            <p>Information received from other sources</p>
          </li>
          <li>
            <p>Information collected automatically</p>
          </li>
        </ul>
      </section>
      <section>
        <p>
          <strong>Information provided directly by you</strong>
        </p>
        <p>
          Location data. We collect information about your location to provide
          geofenced gameplay for beast catching at hotspots around a city, even
          when the app has been backgrounded. However, we do not store, retain,
          or share your location data. Your location is used solely in real-time
          to provide interactive, location based AR experiences.
        </p>
        <p>
          Account and user information. We collect information about you when
          you register an account, create or modify user information, and/or
          provide any other related information to access or use our Sites,
          products, or services. For example, you provide your email address
          when you join our mailing list on our Site. If you use our Sites,
          products, or services, we may also process, without limitation, the
          following information: name (pseudonym or nickname), telephone number
          (e.g., in cases when you request individual technical support or
          assistance), and information about your purchases and activation of
          the Applications (preference categories and other types of
          preference-related advertising).
        </p>
        <p>
          Information you provide by filling out specific forms. Jippi also
          processes personal information you provide by filling out specific
          forms. For example, when downloading a beta version of an Application
          from the Site, subscribing to email newsletters, contacting Jippi for
          technical support or via feedback forms, etc., Jippi may set out the
          requirements for the information that is essential to provide the user
          with our products or services. For instance, for technical support
          purposes you may be asked to provide your contact information, a
          description of the problem you are experiencing, and documentation,
          screenshots, or information that would be helpful in resolving the
          issue. If some information is not marked by Jippi as essential, you
          may submit or disclose such information at your own discretion.
        </p>
        <p>
          Commercial and payment information. If you purchase our products or
          services, we may process the following information: your name, email
          address, your country, state, city, postal code, information about
          your purchases, payment methods, payment currencies, and other payment
          data. If you use a credit card to purchase a Jippi product, the credit
          card number is encrypted as it passes over the Internet. Cardholder
          data is not kept in or recorded by Jippi, it is simply transferred to
          your bank, credit card company, and payment-processing company. In
          this respect, an online credit card purchase is similar to a credit
          card purchase at any store. Information about you such as name, email
          address, your country, state, city, postal code, and purchased product
          or service is retained by Jippi for further delivery and/or
          registration of the product or service.
        </p>
      </section>
      <section>
        <p>
          <strong>Information received from other sources</strong>
        </p>
        <p>
          Information received from distributors or partners. We may obtain
          personal information about you from our distributors or partners, for
          example, if you purchase the products or services through one of these
          partners. The personal information we may receive includes business
          contact information, including name, surname, job titles, and email
          addresses. Please note that Jippi acts as a processor with respect to
          the information received from distributors or partners.
        </p>
        <p>
          Information received from Business administrators. We may obtain
          personal information about you from Business administrators, for
          example, if the Business would like to share its license rights with
          you, such Business may provide us with your email so that we can grant
          you the foregoing rights.
        </p>
        <p>
          Information received from payment processors. When you save your
          credit card information, it is saved on the respective payment
          processor's servers and the payment processor gives Jippi a token that
          you can reuse for future payments. It is not possible to reconstruct
          your credit card information from the token. For due performance of
          our tax obligations and for subscription renewal, we may only obtain
          the following information from our payment processors: the last 4
          digits of the credit card associated with your purchase, the country
          of the bank that issued the card, the network, and expiration month
          and year.
        </p>
      </section>
      <section>
        <p>
          <strong>Information collected automatically</strong>
        </p>
        <p>
          Device and usage information. For website personalization, conducting
          research and for improvement of our Site and Applications, we gather
          information about the devices you use to access our Sites (desktop and
          mobile) and other information about you when you visit and interact
          with any of our Sites or Applications; this may include the IP address
          and other geolocation data, browser type and version, operating
          system, and system language, referring website, time of access,
          traffic source, hardware model and other device-specific information,
          unique device identifiers, statistical information about your actions
          on the Site and Application use, information about the data that is
          being sent and received via the Application, and other device and
          usage information.
        </p>
        <p>
          Cookies and similar technologies. We and our third-party service
          providers and partners, such as our advertising and analytics service
          providers and partners, use cookies and other similar technologies
          (e.g., device identifiers, web beacons, and pixels) to provide
          functionality and to recognize you across different services and
          devices.
        </p>
        <p>
          Google Analytics. Google Analytics is a web analytics service provided
          by Google, Inc. Google Analytics uses cookies to help analyze how
          visitors use the Site. The information generated by the cookies is
          transmitted to and stored by Google. Google Analytics helps us
          understand the use of our Site by visitors. Because Google Analytics
          requires cookies to function, users who wish to avoid tracking by
          Google Analytics while on our Site can disable cookies in their
          browser. By using this Site, you consent to the processing of data
          about you by Google in the manner and for the purposes set out above.
          For more information about how Google uses data, please visit
          www.google.com/policies/privacy/partners/.
        </p>
        <p>
          For more information, please see our Cookies section below, which
          includes information on how to disable cookies.
        </p>
      </section>
      <section>
        <p>3. Use of information we collect</p>
        <p>3.1. Jippi uses personal information for the following purposes:</p>
        <ul>
          <li>
            <p>Execution and performance of the agreements with you</p>
          </li>
          <li>
            <p>Registration of your account</p>
          </li>
          <li>
            <p>Personalization of products and special offers</p>
          </li>
          <li>
            <p>
              Identification of you in the course of fulfilling Jippi's
              obligations under our agreements with you
            </p>
          </li>
          <li>
            <p>
              Communication with you for the purposes of informational support
              and improvement of the Applications, including notification
              through third parties
            </p>
          </li>
          <li>
            <p>Improvement of the Site and Applications</p>
          </li>
          <li>
            <p>
              Distribution of advertising materials from Jippi and/or third
              parties among users as the targeted audience
            </p>
          </li>
          <li>
            <p>Targeting of advertising and/or informational materials</p>
          </li>
          <li>
            <p>
              Conducting marketing, analytical, and other research based on
              automatically collected information
            </p>
          </li>
          <li>
            <p>
              Verification of accounts and activity, detection, prevention, and
              responding to potential or actual security incidents and
              monitoring and protecting against other malicious, deceptive,
              fraudulent, or illegal activity
            </p>
          </li>
          <li>
            <p>Compliance with legal requirements</p>
          </li>
        </ul>
        <p>
          3.2. Jippi may share personal information with service providers as
          described below within the limits of the purposes listed above.
        </p>
        <p>
          3.3. If the information submitted by you contains personal
          information, by using our Site, services, or products or by providing
          personal information to us, you freely and voluntarily grant Jippi
          written informed consent to process that data for the purposes defined
          by this Policy. This includes any operation or set of operations that
          is performed on personal data or on sets of personal data, whether or
          not by automated means, such as collection, recording, organization,
          structuring, storage, adaptation or alteration, retrieval,
          consultation, use, disclosure by transmission, dissemination or
          otherwise making available, alignment or combination, restriction,
          erasure or destruction. This consent may be revoked at any time by
          submitting a request to our Support Team.
        </p>
      </section>
      <section>
        <p>4. Privacy Policy principles</p>
        <p>Our Privacy Policy is based on the following principles:</p>
        <ul>
          <li>
            <p>
              We process your data exclusively for the purposes and according to
              the methods provided when the data was collected.
            </p>
          </li>
          <li>
            <p>We use the collected data only for purposes specified herein.</p>
          </li>
          <li>
            <p>
              We let third parties access the provided data exclusively for the
              purposes specified herein.
            </p>
          </li>
          <li>
            <p>
              We respond to requests for canceling, changing, or adding to the
              data provided.
            </p>
          </li>
          <li>
            <p>We ensure proper and lawful data management.</p>
          </li>
          <li>
            <p>
              We safeguard your privacy and apply appropriate security measures
              to protect the confidentiality, integrity, and availability of the
              data provided.
            </p>
          </li>
        </ul>
      </section>
      <section>
        <p>5. Sharing your information</p>
        <p>
          5.1. We share information we collect about you in the ways discussed
          below.
        </p>
        <ul>
          <li>
            <p>
              <strong>Service Providers.</strong> We share your personal
              information with third-party service providers that help us
              operate, provide, improve, integrate, customize, support and
              market our Sites, Applications, and services. Following our
              instructions, these parties may process your personal information
              in the course of providing the relevant services to us.
              Third-party service providers only have access to the personal
              information they need to perform those services. In particular, we
              use third-party service providers of payment processing, fraud
              prevention, crash reporting services, analytics services,
              marketing services, advertising services, email communication and
              customer support services, and hosting and other information
              technology services.
            </p>
          </li>
          <li>
            <p>
              <strong>Partners.</strong> We may share your information with our
              partners that help us to drive traffic to our Sites or otherwise
              promote our Applications and services, as well as with our Bitcoin
              rewards partner Joltz.
            </p>
          </li>
          <li>
            <p>
              <strong>Business administrators.</strong> If you are using the
              Application activation key that is provided to you by a Business
              or if you were invited to use the Jippi Site, services, and
              products by a Business, we may provide your email, Application
              activation information, Application use status (last use date) and
              operating system information to the relevant Business
              administrators for license management purposes.
            </p>
          </li>
          <li>
            <p>
              <strong>Business transfers.</strong> If we are involved in a
              merger, acquisition, financing due diligence, reorganization,
              bankruptcy, receivership, sale of all or a portion of our assets,
              or transition of a service to another provider, your personal
              information and other information may be transferred to a
              successor or affiliate as part of that transaction.
            </p>
          </li>
          <li>
            <p>
              <strong>Compelled disclosure.</strong> We reserve the right to use
              or disclose your personal information if required by law or if we
              reasonably believe that use or disclosure is necessary to protect
              our rights, protect your safety or the safety of others,
              investigate fraud, or comply with a law, court order, or legal
              process.
            </p>
          </li>
          <li>
            <p>
              <strong>Others.</strong> We may share your personal information in
              other ways if you have asked us to do so or have given consent.
              For example, with your consent, we post user testimonials that may
              identify you.
            </p>
          </li>
        </ul>
        <p>
          5.2. These transfers will be typically based on our legitimate
          interest or agreed upon in the contract.
        </p>
        <p>
          5.3. If you prefer that we not share your information with third
          parties for marketing purposes, please contact our Support Team.
        </p>
      </section>
      <section>
        <p>6. Retention of personal information</p>
        <p>
          If we collect your personal information, the length of time we retain
          it is determined by a number of factors, including the purpose for
          which we use that information and our obligations under applicable
          laws, but no longer than it is reasonably necessary in light of the
          purposes set out above. We may need your personal information to
          establish, bring, or defend legal claims. For this purpose, we retain
          your personal information for 7 years after the date it is no longer
          needed by us for any transactions, fulfilling contract purposes or
          marketing communications.
        </p>
      </section>
      <section>
        <p>7. Cookie notice</p>
        <p>
          7.1. This notice is designed to help you understand what cookies are,
          how Jippi uses them, and the choices you have in regard to their use.
          By continuing to use our Sites, you are agreeing to the use of cookies
          in the manner described in this notice.
        </p>
        <p>7.2. What are cookies?</p>
        <p>
          Cookies are small text files that are stored in your browser or on the
          hard drive of your computer or another device when you visit our Site.
          This allows the Site to recognize you as a user either for the
          duration of your visit (a “session cookie”) or for repeat visits (a
          “persistent cookie”). Cookies are not harmful and do not contain any
          personally identifiable information such as your home address, date of
          birth, or credit card details.
        </p>
        <p>7.3. The cookies we use fall into four broad types:</p>
        <ul>
          <li>
            <p>
              <strong>Strictly necessary cookies.</strong> These cookies are
              essential for helping you to navigate our Site and use its
              features, such as accessing secure areas of the Site. Without
              these cookies, the services you have requested, such as setting up
              an account, cannot be provided. These cookies do not gather
              information about you that could be used for marketing or
              remembering where you've been on the Internet.
            </p>
          </li>
          <li>
            <p>
              <strong>Analytical/performance cookies.</strong> In order to keep
              the Site, services, and products relevant, easy to use, and up to
              date, we use web analytics services to help us understand how
              people use our Site. For example, we can see which parts of the
              Site and products are most popular, identify when and where errors
              occur, and test different versions of a page or feature to see
              which works best.
            </p>
          </li>
          <li>
            <p>
              <strong>Functionality cookies.</strong> These cookies allow
              websites and applications to remember choices you make (such as
              your user name, language, or the region you are in) and provide
              enhanced, more personalized features. The information these
              cookies collect is usually anonymized, which means we cannot
              identify you personally. They do not gather any information about
              you that could be used for selling, advertising, or remembering
              where you've been on the Internet but help us provide advertising
              that is more relevant to you.
            </p>
          </li>
          <li>
            <p>
              <strong>Targeted marketing cookies.</strong> We also use cookies
              to assist in targeted advertising. Without these cookies, online
              advertisements you encounter will be less relevant to you and your
              interests. We also use them to measure the effectiveness of our
              marketing communications, for example, by receiving signals to
              inform us if you have responded to an advertisement that we have
              sent you.
            </p>
          </li>
        </ul>
        <p>
          7.4. The information stored in cookies is safe and anonymous to any
          external third party, and your account security is never compromised.
          For information on how to disable cookies, please consult the “Help”
          tab in your browser via its menu bar. Should you need further
          assistance, please contact us via the email address below.
        </p>
      </section>
      <section>
        <p>8. Your consent to receive information from Jippi</p>
        <p>
          8.1. Our Site and Applications offer news and information regarding
          our products by subscription, including email newsletters. If you
          decide to subscribe to these services, you may be asked to provide
          your email address and name. By doing so, you agree to receive emails
          from Jippi with information about updates and any other information
          related to the Applications as well as other types of information from
          Jippi, including advertisements. We may use the information you
          provide to help develop content that is relevant to our users. You are
          free to cancel your subscription(s) at any time.
        </p>
        <p>
          8.2. Where required by law we will only send you marketing information
          if you consent to us doing so at the time you provide us your personal
          information. You may opt out of receiving such emails by following the
          instructions in each promotional email we send you.
        </p>
        <p>
          8.3. To cancel your subscription, follow the instructions at the
          bottom of the emails or email us at hello@jippi.app and request the
          deletion of your data from our mailing list. In this case, Jippi will
          keep your personal information in its database but will not contact
          you unless you change your mind on this issue.
        </p>
      </section>
      <section>
        <p>9. Protection of your information</p>
        <p>
          9.1. Jippi stores information about users and takes the appropriate
          organizational and technical security measures to protect that
          information from unauthorized access and disclosure in compliance with
          internal rules and procedures (including GDPR).
        </p>
        <p>
          9.2. We maintain administrative and technical safeguards for the
          protection of personal information. These safeguards include, without
          limitation, the following:
        </p>
        <ul>
          <li>
            <strong>Administrative safeguards:</strong> Access to personal
            information about users is limited to authorized personnel who have
            a legitimate need to know it based on their job descriptions – for
            example, employees who provide technical support to end users. All
            such data is subject to password protection. In the case of
            third-party contractors who process personal information on our
            behalf, similar requirements are imposed.
          </li>
        </ul>
        <p>
          9.3. If you create an account, you are responsible for maintaining the
          confidentiality of the password for that account.
        </p>
      </section>
      <section>
        <p>10. Your rights</p>
        <p>
          Under data protection law as the data subject, you have rights we need
          to make you aware of. The rights available to you depend on our reason
          for collecting and processing your information.
        </p>
        <ul>
          <li>
            <p>
              <strong>Right of access.</strong> You have the right to ask us for
              copies of your personal information. This right always applies.
              However, there are some exceptions, when you may not always
              receive all the information we process about you.
            </p>
          </li>
          <li>
            <p>
              <strong>Right to rectification.</strong> You have a right to ask
              us to correct or complete your personal information if you find
              that it is inaccurate or incomplete. If we share your personal
              information with others, we will ask them to correct or complete
              it as soon as it is possible.
            </p>
          </li>
          <li>
            <p>
              <strong>Right to erasure.</strong> You may ask us to delete your
              personal information in certain circumstances, for example, if
              your personal information is no longer needed for its processing
              or if you want to withdraw your consent. If we share your personal
              information with others, we will notify them that they need to
              erase your information where it is possible.
            </p>
          </li>
          <li>
            <p>
              <strong>Right to restriction of processing.</strong> You have the
              right to ask us to restrict the processing of your data in cases
              determined by law.
            </p>
          </li>
          <li>
            <p>
              <strong>Right to object to processing.</strong> You have the right
              to request that we restrict the processing of your personal
              information, under certain conditions.
            </p>
          </li>
          <li>
            <p>
              <strong>Right to data portability.</strong> You have the right to
              request that we transfer the information that we have collected to
              another organization or directly to you, under certain conditions.
            </p>
          </li>
          <li>
            <p>
              <strong>Right to withdraw a consent.</strong> If we rely on your
              consent to process your personal information, you have the right
              to withdraw that consent at any time, but this will not affect any
              processing of your data that has already taken place.
            </p>
          </li>
          <li>
            <p>
              <strong>
                Right to make a complaint with a supervisory authority.
              </strong>{" "}
              We work to high standards when it comes to processing your
              personal information. If you have queries or concerns, please
              contact us at hello@jippi.app and we'll respond. If you have a
              concern about our privacy practices, including the way we handle
              your personal information, you can report it to the data
              protection authority that is authorized to hear those concerns.
            </p>
          </li>
          <li>
            <p>
              <strong>Right to non-discrimination.</strong> We will not
              discriminate against you (e.g., through denying provision of
              products or services or providing a different level or quality of
              products or services) for exercising any of the rights available
              to you.
            </p>
          </li>
        </ul>
      </section>
      <section>
        <p>11. Other important privacy information</p>
        <ul>
          <li>
            <p>
              <strong>Sensitive information.</strong> Jippi does not collect
              sensitive personal information such as sexual preferences,
              religion, political views, health conditions, or other sensitive
              personal information. We will not request it from you, and neither
              do we need it.
            </p>
          </li>
          <li>
            <p>
              <strong>Data on children.</strong> Persons under the age of 13, or
              any higher minimum age in the jurisdiction where that person
              resides, should not provide us with personal information unless
              they have the verifiable permission of their parent(s) or legal
              guardian(s). Our Site, products, and services are not intended for
              children under the age of 13 or equivalent minimum age in the
              relevant jurisdiction. We do not knowingly collect personal
              information from children but, if a parent, guardian, or anyone
              else believes that a child has submitted personal information to
              us and would like us to delete that information, they may contact
              us at hello@jippi.app.
            </p>
          </li>
          <li>
            <p>
              <strong>International transfers of personal information.</strong>{" "}
              We collect information globally and may transfer, process, and
              store your information outside of your country of residence, to
              wherever we or our third-party service providers operate for the
              purpose of providing you our products and services. Whenever we
              transfer your information, we take steps to protect it. To
              facilitate our global operations, we transfer information globally
              and allow access to that information from countries in which Jippi
              owned or operated companies have operations for the purposes
              described in this Policy. These countries may have data protection
              laws less stringent than or otherwise different from the laws in
              effect in the country in which you are located. Moreover, some of
              the third parties described in this Policy, which provide services
              to us under contract, are based in other countries that may not
              have equivalent privacy and data protection laws to the country in
              which you reside. Where we transfer your personal data as
              described above, we will take steps to ensure that your personal
              data receives adequate security protection where it is processed
              and your rights continue to be protected pursuant to the
              applicable data protection law, including through the use of
              Standard Contractual Clauses approved by the European Commission.
              If you would like to receive more information on the safeguards
              that we implement as described above, please contact us as
              indicated below.
            </p>
          </li>
          <li>
            <p>
              <strong>Third-party websites.</strong> The Site and Applications
              may contain links to third-party websites. When the user follows
              these links, the user leaves the Site/Application, so Jippi takes
              no responsibility for the safety of user data during the use of
              third-party websites. Such websites may have their own privacy
              policy setting out the rules of collection and storage of user
              data. This Policy does not cover the data that is submitted to,
              stored, or used by third-party websites. We recommend that you
              carefully review the privacy policy of such third parties when
              using their websites. Jippi is not responsible for the use of your
              data by any third parties that you interact with in the course of
              using the Site or Application.
            </p>
          </li>
        </ul>
      </section>
      <section>
        <p>12. Amendments</p>
        <p>
          12.1. This Policy may be amended or canceled by Jippi at its own
          discretion without prior notification of the user. We recommend that
          you regularly read the latest version of the Policy.
        </p>
        <p>
          12.2. A new version of the Policy is effective from the moment of its
          publication unless specified otherwise by such new version of the
          Policy.
        </p>
        <p>
          12.3. We value your trust and are always prepared to answer your
          questions about the handling of your personal information. If you have
          any questions or concerns, contact our Support Team.
        </p>
      </section>
      <section>
        <p>13. Principal office</p>
        <p>
          Jippi, Inc. 131 Continental Dr, Suite 305, Newark, Delaware, 19713
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
