import React from "react";
import styles from "./headline.module.scss";

const Headline = () => {
  return (
    <div className={styles.cover}>
      <div className={styles.column}>
        <div className={styles.row}>
          <img
            src="/jippi_blue_square.png"
            alt="Tribe Clash logo"
            className={styles.logo}
          />
          <div className={styles.jippiLogo} />
        </div>
        <p className={styles.title}>Catch. Win. Conquer.</p>
        <p className={styles.subtitle}>
          Jippi is an augmented reality adventure game with a bitcoin twist.
        </p>
        <div className={styles.rowAlt}>
          <img
            onClick={() =>
              window.open(
                /// App sore link
                "https://apps.apple.com/us/app/jippi/id6471380135",
                /// Test flight link
                // "https://testflight.apple.com/join/3oCIo3wn",
                "_blank"
              )
            }
            src="/app_store_badge.png"
            alt="person"
            className={styles.badge}
          />
          <img
            onClick={() =>
              window.open(
                "https://play.google.com/store/apps/details?id=com.jippiApp.jippi.prod",
                "_blank"
              )
            }
            src="/google_play_badge.png"
            alt="person"
            className={styles.badge}
          />
        </div>
      </div>
      <div className={styles.container}>
        <img
          src="/iphone_rounded.svg"
          alt="iPhone with Jippi App"
          className={styles.iPhonePhrame}
        />
        <video
          className={styles.iPhoneVideo}
          autoPlay={true}
          preload="auto"
          loop
          playsInline
          muted
          disablePictureInPicture
        >
          <source src="/catch_beast_new.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
};

export default Headline;
