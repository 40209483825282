import React from "react";
import styles from "./SXSW.module.scss";

const SXSW = () => {
  const script = document.createElement("script");
  script.src = "https://js.hsforms.net/forms/v2.js";
  document.body.appendChild(script);

  script.addEventListener("load", () => {
    // @TS-ignore
    if (window.hbspt) {
      // @TS-ignore
      window.hbspt.forms.create({
        portalId: "44829060",
        formId: "51fa724c-aabe-4fde-b081-06429bf14b41",
        target: "#hubspotForm",
      });
    }
  });

  return (
    <div className={styles.cover}>
      <div className={styles.column}>
        <div className={styles.row}>
          <img
            src="/jippi_black.png"
            alt="Tribe Clash logo"
            className={styles.logo}
          />
        </div>
        <p className={styles.title}>Catch. Win. Conquer.</p>
        <p className={styles.subtitle}>
          Introducing to the world's first Pokémon GO-inspired bitcoin AR game.
        </p>
        <div id="hubspotForm"></div>
      </div>
      <div className={styles.container}>
        <img
          src="/iphone_rounded.svg"
          alt="iPhone with Jippi App"
          className={styles.iPhonePhrame}
        />
        <video
          className={styles.iPhoneVideo}
          autoPlay={true}
          preload="auto"
          loop
          playsInline
          muted
          disablePictureInPicture
        >
          <source src="/catch_beast_new.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
};

export default SXSW;
