import React, { useState } from "react";
import styles from "./signUp.module.scss";
import Modal from "react-modal";

const SignUp = () => {
  const script = document.createElement("script");
  script.src = "https://js.hsforms.net/forms/v2.js";
  document.body.appendChild(script);

  script.addEventListener("load", () => {
    // @TS-ignore
    if (window.hbspt) {
      // @TS-ignore
      window.hbspt.forms.create({
        portalId: "44829060",
        formId: "51fa724c-aabe-4fde-b081-06429bf14b41",
        target: "#hubspotForm",
      });
    }
  });

  return (
    <div className={styles.cover}>
      <div className={styles.column}>
        <p style={styles.modalBody}>
          Enter your details and we'll email you instructions on how to receive
          your free bitcoin.
        </p>
        <div id="hubspotForm"></div>
      </div>
    </div>
  );
};

export default SignUp;
