import React from "react";
import styles from "./terms.module.scss";
import { Link } from "react-router-dom";

const TermsOfService = () => {
  return (
    <div className={styles.cover}>
      <p className={styles.title}>Jippi Terms of Service</p>
      <section>
        <p>INTRODUCTION</p>
        <p>Last Updated: March 6th, 2025</p>
        <p>
          Jippi, Inc. makes this website (the “Site”), including all
          information, documents, text, directories, data, icons,
          communications, files, photos, videos, graphics, music, sound,
          software, and services available through the Site (collectively, the
          “Content”) available for your use subject to the terms and conditions
          set forth in this document and any changes to this document that Jippi
          may publish from time to time (collectively, the “Terms of Service”).
        </p>
      </section>
      <section>
        <p>ACCEPTANCE OF TERMS OF SERVICE</p>
        <p>
          By accessing or using this Site in any way, including, without
          limitation, using or downloading any Content, or merely browsing the
          Site, you agree to and are bound by the Terms of Service. If you do
          not agree to these Terms of Service, please do not use this Site. If
          you breach any of the Terms of Service, your authorization to use this
          Site automatically terminates, and any Content downloaded or printed
          from the Site in violation of the Terms of Service must be immediately
          destroyed. We reserve the right, at our discretion, to update or
          revise these Terms of Service. Please check the Terms of Service
          periodically for changes. Your continued use of this Site following
          the posting of any changes to the Terms of Service constitutes
          acceptance of those changes.
        </p>
      </section>
      <section>
        <p>PROPRIETARY RIGHTS</p>
        <p>
          You acknowledge and agree that all Content available on this Site is
          protected by copyright, trademark, patent, trade secret, and other
          proprietary rights and laws. For example, Jippi owns a copyright in
          the selection, organization, arrangement, and enhancement of the
          Content, as well as in the Content original to itself. Third parties
          whose Content appears on the Site own the copyright in the Content
          original to them. The look and feel of the Site, including our color
          combinations, button shapes, and other graphical elements, are also
          Jippi trademarks.
        </p>
      </section>
      <section>
        <p>USE OF THIS SITE</p>
        <p>
          This Site and its Content are provided for your personal informational
          and entertainment use and may only be used for lawful purposes. By
          using this Site, you agree not to reproduce, republish, display,
          frame, download, transmit, modify, rent, loan, sell, assign,
          distribute, reverse engineer, adapt, edit, or create derivative works
          based on the Site or its Content, except as expressly authorized
          herein. You also agree that you will not store, reproduce, transmit,
          publicly display, publicly perform, distribute, publish, broadcast, or
          otherwise make available the Content, including, without limitation,
          the radio, TV, or other audio-visual content directory listings
          available on and through this Site (collectively, the “Directory”), or
          any portion of the Directory, whether electronically or in any other
          form, and whether for commercial purposes or any purpose other than
          your own personal use, unless approved in writing by Jippi. Any
          portion of the Directory that is downloaded or otherwise reproduced
          from this Site may be retained only for a temporary period of time.
          Systematic retrieval of data or other content from the Directory or
          other use of such data and other Content, directly or indirectly, to
          create or compile a collection, database or directory without written
          permission from Jippi is prohibited, and you agree not to use any data
          mining, robots, or similar data gathering and extraction methods in
          connection with the Site.
        </p>
        <p>
          You may not post on or transmit through any community, upload or
          download areas of the Site any Content that (i) violates or infringes
          in any way upon the rights of others, including, without limitation,
          copyrights and trademarks, (ii) is unlawful, threatening, abusive,
          defamatory, invasive of privacy or publicity rights, vulgar, obscene,
          profane, indecent or otherwise objectionable, (iii) encourages conduct
          that would constitute a criminal offense, (iv) gives rise to civil
          liability, or (v) otherwise violates any law. You are responsible for
          determining whether posting or transmitting any particular Content
          violates any of the provisions above or any laws. You shall be solely
          liable for any damages resulting from any infringement of copyright,
          trademark, or another proprietary right or any other harm resulting
          from any Content you upload, post, or submit. You also may not
          undertake any conduct that, in the judgment of Jippi, restricts or
          inhibits any other user from using or enjoying the Site, including,
          without limitation, the community areas.
        </p>
        <p>
          You may not harvest or collect information, including email addresses,
          user names, or other contact information about Site users, for the
          purpose of sending unsolicited bulk email or instant messages.
        </p>
        <p>
          Some of the features of this Site, such as forums, require
          registration. You agree to provide only accurate, complete, and
          updated registration information, and you agree to keep such
          information current. You may not select or use an email that
          impersonates another person or entity, belongs to another person,
          violates the intellectual property rights of any person or entity, or
          that Jippi considers to be offensive.
        </p>
        <p>
          The use of some of the features and all of the software available on
          this Site is governed by separate Terms of Service and/or licenses.
          You are not authorized to use such features or software unless you
          agree to abide by the applicable terms/licenses.
        </p>
        <p>
          When you download or purchase any software from this Site, Jippi
          reserves the right to request information from you. You may also be
          required to create a user account.
        </p>
        <p>
          The user account is a section of the Site that contains the
          information necessary for the execution of the license agreement, for
          example, information about the Jippi products you have purchased or
          your subscription terms.
        </p>
        <p>
          You have sole access to your account and you are responsible for
          maintaining the confidentiality of your password and account. All
          actions performed under your account are considered to have been
          performed by you.
        </p>
        <p>
          Jippi reserves the right to change the functionality of your account
          at its discretion without prior notice.
        </p>
        <p>
          As a Jippi customer, you agree to receive emails containing
          information about recent updates and new products from Jippi as well
          as advertisements.
        </p>
        <p>
          Jippi may send you emails containing information about new software
          versions as well as other information related to the purchased product
          and advertisements. You can unsubscribe from these communications at
          any time. To do so, follow the unsubscribe information contained in
          the emails or send an email to hello@jippi.app with your request to be
          removed from our mailing list.
        </p>
        <p>
          You agree that your reviews of Jippi services and products may be
          published without your additional consent on the Jippi Sites and used
          for commercial purposes, including in Jippi ads.
        </p>
      </section>
      <section>
        <p>MONITORING</p>
        <p>
          Jippi has the right, but not the obligation, to monitor the Content of
          the Site, including, without limitation, the download areas, to
          determine compliance with the Terms of Service and any other operating
          rules that may be established by Jippi from time to time. Jippi has
          the right at its sole discretion to edit, refuse to post, or remove
          any Content submitted to or posted on the Site. Without limiting the
          foregoing, Jippi has the right, but not the obligation, to remove any
          Content that it, in its sole discretion, finds to be in violation of
          the provisions of these Terms of Service, otherwise objectionable, or
          no longer current. Users shall remain solely responsible for the
          Content they post or submit. You acknowledge and agree that Jippi
          shall not assume or have any liability for any action or inaction with
          respect to any conduct within the community areas or any communication
          or posting in the community areas.
        </p>
      </section>
      <section>
        <p>PRIVACY</p>
        <p>
          The Jippi Privacy Policy explains how your information is collected
          and used and the choices you have with respect to that information.
          You consent to Jippi using your registration information and other
          information collected on this Site under the terms of the{" "}
          <Link to={`/privacy-policy`}>Jippi Privacy Policy</Link>.
        </p>
      </section>
      <section>
        <p>DISCLAIMER OF WARRANTIES</p>
        <p>
          THE SITE AND THE CONTENT ARE PROVIDED WITH ALL FAULTS ON AN “AS IS”
          AND “AS AVAILABLE” BASIS. JIPPI, ITS LICENSORS, AND OTHER SUPPLIERS
          DISCLAIM ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING THE
          WARRANTIES THAT THE SITE AND CONTENT ARE FREE OF DEFECTS, VIRUS-FREE,
          AND ABLE TO OPERATE ON AN UNINTERRUPTED BASIS, THAT THEY WILL MEET
          YOUR REQUIREMENTS, OR THAT ERRORS WILL BE CORRECTED, AND THE IMPLIED
          WARRANTIES THAT THE SITE AND CONTENT ARE MERCHANTABLE, OF SATISFACTORY
          QUALITY, ACCURATE, FIT FOR A PARTICULAR PURPOSE OR NEED, OR
          NON-INFRINGING, UNLESS SUCH IMPLIED WARRANTIES ARE LEGALLY INCAPABLE
          OF EXCLUSION.
        </p>
        <p>
          THE CONTENT ON THIS SITE IS PROVIDED FOR GENERAL INFORMATION PURPOSES
          ONLY AND DOES NOT CONSTITUTE LEGAL OR OTHER PROFESSIONAL ADVICE OR AN
          OPINION OF ANY KIND. YOU SHOULD CONSULT AN APPROPRIATE PROFESSIONAL
          FOR SPECIFIC ADVICE TAILORED TO YOUR SITUATION.
        </p>
        <p>YOUR USE OF THIS SITE IS SOLELY AT YOUR OWN RISK.</p>
      </section>
      <section>
        <p>LIMITATION OF LIABILITY</p>
        <p>
          UNDER NO CIRCUMSTANCES SHALL JIPPI, ITS AFFILIATES, DIRECTORS,
          EMPLOYEES, DISTRIBUTORS, SUPPLIERS, AGENTS, OR RESELLERS
          (COLLECTIVELY, THE “JIPPI GROUP”) BE LIABLE FOR ANY INDIRECT, SPECIAL,
          INCIDENTAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES THAT RESULT FROM THE
          USE OF, OR INABILITY TO USE, THIS SITE AND ITS CONTENT. THIS
          LIMITATION APPLIES WHETHER THE ALLEGED LIABILITY IS BASED ON CONTRACT,
          TORT, NEGLIGENCE, STRICT LIABILITY, OR ANY OTHER BASIS, EVEN IF JIPPI
          HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGE. BECAUSE SOME
          JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL
          OR CONSEQUENTIAL DAMAGES, THE JIPPI GROUP'S LIABILITY IN SUCH
          JURISDICTIONS SHALL BE LIMITED TO THE EXTENT PERMITTED BY LAW.
          ADDITIONALLY, UNDER NO CIRCUMSTANCES SHALL THE JIPPI GROUP BE HELD
          RESPONSIBLE OR LIABLE, DIRECTLY OR INDIRECTLY, FOR ANY LOSS OR DAMAGE
          CAUSED OR ALLEGED TO HAVE BEEN CAUSED TO YOU IN CONNECTION WITH THE
          USE OF OR RELIANCE ON ANY CONTENT, GOODS OR SERVICES AVAILABLE ON OR
          THROUGH ANY EXTERNAL SITES LINKED FROM THE SITE.
        </p>
      </section>
      <section>
        <p>INDEMNIFICATION</p>
        <p>
          Upon request by Jippi, you agree to defend, indemnify, and hold
          harmless Jippi, other affiliated companies, and their employees,
          contractors, officers, and directors from all liabilities, claims, and
          expenses, including attorney's fees, that arise from your misuse of
          this Site in a manner not in strict conformance with these Terms of
          Service. Jippi reserves the right, at its own expense, to assume the
          exclusive defense and control of any matter otherwise subject to
          indemnification by you, in which event you will cooperate with Jippi
          in asserting any available defenses.
        </p>
      </section>
      <section>
        <p>INTERNATIONAL USE</p>
        <p>
          The Content provided on this Site is not intended for use in any
          jurisdiction where such use would be contrary to law. You are
          responsible for compliance with all applicable local laws and
          regulations.
        </p>
      </section>
      <section>
        <p>SEVERABILITY AND INTEGRATION</p>
        <p>
          Unless otherwise specified herein, this agreement constitutes the
          entire agreement between you and Jippi with respect to this Site and
          supersedes all prior or contemporaneous communications and proposals
          (whether oral, written, or electronic) between you and Jippi with
          respect to this Site. If any part of these Terms of Service is held
          invalid or unenforceable, that portion shall be construed in a manner
          consistent with applicable law to reflect, as nearly as possible, the
          original intentions of the parties, and the remaining portions shall
          remain in full force and effect.
        </p>
      </section>
      <section>
        <p>TERMINATION</p>
        <p>
          Jippi reserves the right, at its sole discretion, to terminate your
          access to all or part of this Site, with or without notice.
        </p>
      </section>
      <section>
        <p>CONTACTS</p>
        <p>For help with Jippi software send an email to hello@jippi.app.</p>
      </section>
    </div>
  );
};

export default TermsOfService;
