import React from "react";
import styles from "./headline.module.scss";

const Headline = () => {
  return (
    <div className={styles.cover}>
      <div className={styles.column}>
        <div className={styles.row}>
          <img
            src="/tribe_clash_icon.png"
            alt="Tribe Clash logo"
            className={styles.logo}
          />
          <div className={styles.jippiLogo} />
        </div>
        <p className={styles.title}>Catch. Win. Conquer.</p>
        <p className={styles.subtitle}>
          Introducing to the world's first Pokémon GO-inspired bitcoin AR game.
        </p>
      </div>
      <div className={styles.container}>
        <img
          src="/iphone_rounded.svg"
          alt="iPhone with Jippi App"
          className={styles.iPhonePhrame}
        />
        <video
          className={styles.iPhoneVideo}
          autoPlay={true}
          preload="auto"
          loop
          playsInline
          muted
          disablePictureInPicture
        >
          <source src="/catch_beast_new.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
};

export default Headline;
