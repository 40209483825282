import React from "react";
import styles from "./howItWorks.module.scss";

const HowItWorks = () => {
  return (
    <div className={styles.cover}>
      <div className={styles.container}></div>
      <div className={styles.container}>
        <img
          src="/beasts.png"
          alt="Hovering person on laptop"
          className={styles.image}
        />
      </div>
      <div className={styles.containerTwo}></div>
      <div className={styles.column}>
        <p className={styles.capitals}>HOW IT WORKS</p>
        <span className={styles.title}>Unleash Your Beast.</span>
        <span className={styles.subtitle}>
          Join a tribe with friends, hunt bitcoin beasts across 21 territories,
          and battle for bitcoin prizes.
        </span>
      </div>
      <div className={styles.container}></div>
    </div>
  );
};

export default HowItWorks;
